.btn {
  position: relative;
  color: $color-default-white;
  display: inline-flex;
  flex-shrink: 0;
  justify-content: center;
  align-items: center;
  margin: 0;
  padding: 17px 37px;
  background-color: $color-geraldine;
  border: none;
  border-radius: 30px;
  outline: none;
  cursor: pointer;
  text-align: center;
  user-select: none;
  appearance: none;
  -webkit-tap-highlight-color: transparent;
  font-weight: 700;
  font-size: 12px;
  line-height: 15px;
  letter-spacing: 0.01em;
  text-transform: uppercase;
  transition: background-color $trans-default;

  &:active {
    opacity: 0.7;
  }

  & svg {
    transition: transform 0.1s ease-in;
  }

  & svg:active {
    transform: scale(0.9);
  }

  @include hover-focus {
    background-color: $color-mulled-wine;

    & span {
      opacity: 0.5;
    }
  }

  @include focus {
    outline: 2px solid $color-geraldine;
    outline-offset: 2px;
  }

  &:disabled {
    opacity: 0.5;
    pointer-events: none;
  }

  @include vp-767 {
    font-size: 10px;
    line-height: 13px;
  }

  &--full-width {
    width: 100%;
    border-radius: 0;
    padding-top: 19px;
    padding-bottom: 18px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

    @include hover-focus {
      background-color: $color-sea-green;
    }
  }

  &--gray {
    background-color: $color-mulled-wine;
    border-radius: 0;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

    @include hover-focus {
      background-color: $color-dodger-blue;
    }
  }
}
