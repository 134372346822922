.control {
  background-color: $color-gainsboro;
  padding-left: 4%;
  padding-right: 4%;

  @media print {
    display: none;
  }

  @include vp-767 {
    padding-left: 0;
    padding-right: 0;
  }

  &__wrapper {
    max-width: 1770px;
    margin: 0 auto;
    min-height: 47px;
    display: grid;
    grid-template-columns: auto 50px 350px minmax(0, 555px) 1fr;
    justify-content: start;

    @media (max-width: 1150px) {
      grid-template-columns: auto 50px 230px minmax(0, 555px) 1fr;
    }

    @include vp-1023 {
      grid-template-columns: auto auto 1fr;
      min-height: 49px;
    }

    // @media (max-width: 500px) {
    //   min-height: 40px;
    // }
  }

  &__box-sort {
    position: relative;
    grid-column: 4 / 5;
    display: flex;
    justify-content: space-between;
    grid-column-gap: 10px;
    padding-right: 30px;

    @include vp-1023 {
      grid-column: 3 / 4;
    }

    @media (max-width: 500px) {
      grid-column: 1 / 4;
      grid-row: 2 / 3;
      flex-direction: column;
      grid-row-gap: 3px;
      width: 80%;
      margin: 0 auto;
      margin-top: 14px;
      padding-right: 0;
    }
  }

  &__date {
    grid-column: 3 / 4;

    @include vp-1023 {
      grid-column: 2 / 3;
    }

    @include vp-767 {
      min-height: 44px;
    }
  }

  &__select {
    width: 100%;
    height: 100%;

    & button {
      min-height: 100%;
    }
  }

  &__btn {

    &--reset {
      position: absolute;
      top: 0;
      right: 3px;
      border-radius: 0;
      padding: 0;
      background-color: transparent;
      height: 100%;

      & svg {
        color: $color-dodger-blue;
        transition: color 0.3s ease-out;
      }

      @include hover-focus {
        background-color: transparent;

        & svg {
          color: $color-mulled-wine;
        }
      }

      @media (max-width: 500px) {
        right: -30px;
      }
    }

    &--out,  &--print {
      border-radius: 0;
      height: 100%;
    }

    &--out {
      grid-column: 1 / 2;
      background-color: $color-nero;
      padding: 0 20px;
    }

    &--print {
      grid-column: 2 / 3;
      padding: 0;

      @include vp-1023 {
        display: none;
      }
    }
  }

  &__btn-text-no-mobile {
    @include vp-1023 {
      display: none;
    }
  }
}
