.modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1000;

  display: flex;
  flex-direction: column;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;

  background-color: rgba($color-default-black, 0.8);
  transform: scale(1.2);
  visibility: hidden;
  opacity: 0;

  transition: opacity $trans-modal,
    transform $trans-modal,
    visibility $trans-modal;

  pointer-events: none;

  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }

  &__wrapper {
    position: relative;
    display: flex;
    flex-shrink: 0;
    justify-content: center;
    align-items: center;
    width: 100%;
    min-height: 100%;
    // padding: 60px 40px;

    transform: scale(0.6);
    transition: transform $trans-modal;

    // @include vp-767 {
    //   padding: 60px 16px;
    // }
  }

  &__overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    cursor: pointer;
    background-color: fade-out($color: $color-mulled-wine-dark, $amount: 0.8);
  }

  &__content {
    position: relative;

    border: 2px solid fade-out($color: $color-default-white, $amount: 0.4);
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
    border-radius: 30px;
    overflow: hidden;
    background-color: fade-out($color: $color-default-white, $amount: 0.4);

    @include vp-767 {
      width: 95%;
      border-radius: 5px;
      border-width: 1px;
      // padding: 20px;
    }
  }

  &__close-btn {
    position: absolute;
    top: 5px;
    right: 11px;
    z-index: 1;

    width: 35px;
    height: 35px;
    padding: 0;

    background: $color-transparent;
    border: none;
    cursor: pointer;

    button {
      padding: 0;
      background-color: transparent;

      svg {
        transition: transform 0.1s ease-in;
        width: 35px;
        height: 35px;
      }
    }

    // & svg {
    //   transition: transform 0.1s ease-in;
    // }

    @include vp-767 {
      width: 30px;
      height: 30px;

      button {
        svg {
          width: 30px;
          height: 30px;
        }
      }
    }

    @include hover-focus {
      & svg {
        border-radius: 30px;
        outline: 2px solid $color-geraldine;
        outline-offset: 2px;
      }
    }

    & svg:active {
      transform: scale(0.9);
    }
  }


  &--responsive {
    .modal__content {
      width: 100%;
    }
  }

  &--fit-content {
    .modal__content {
      width: auto;

      @include vp-767 {
        width: 100%;
      }
    }
  }

  &--no-scale {
    transform: none;
  }

  &--preload {
    transition: none;
  }

  &.is-active {
    transform: scale(1);
    visibility: visible;
    opacity: 1;
    pointer-events: auto;

    .modal__overlay {
      transform: scale(1);
    }

    .modal__wrapper{
      transform: scale(1);
    }
  }
}
