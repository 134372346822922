@font-face {
  font-style: normal;
  font-weight: 400;
  font-family: "Inter";
  font-display: swap;
  src:
    url("../fonts/inter-v12-cyrillic_latin-regular.woff2") format("woff2"),
    url("../fonts/inter-v12-cyrillic_latin-regular.woff") format("woff");
}

@font-face {
  font-style: normal;
  font-weight: 700;
  font-family: "Inter";
  font-display: swap;
  src:
    url("../fonts/inter-v12-cyrillic_latin-700.woff2") format("woff2"),
    url("../fonts/inter-v12-cyrillic_latin-700.woff") format("woff");
}
