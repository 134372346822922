.custom-input,
.custom-textarea {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  font-size: 16px;
  line-height: 19px;

  @include vp-767 {
    font-size: 12px;
    line-height: 14px;
  }

  @include hover {
    input {
      outline-width: 2px;
    }
  }

  &:focus-within {
    input {
      outline-width: 2px;
      outline-color: $color-sea-green;
    }
  }

  &.is-value,
  &:focus-within {
    color: $color-default-black;

    .custom-input__label,
    .custom-textarea__label {
      display: none;
    }
  }

  label {
    flex-grow: 1;
    position: relative;
    display: flex;
    flex-direction: column;
  }

  input,
  textarea {
    flex-grow: 1;
    font: inherit;
    color: currentColor;
    border: none;
    background-color: fade-out($color: $color-default-white, $amount: 0.9);
    outline: 1px solid $color-default-white; // для скрытия артефактов при autofill
    outline-offset: -1px;
    transform: translateZ(0); // для Safari, где смещается текст при hover

    &:-webkit-autofill {
      box-shadow: inset 0 0 0 1000px $color-mulled-wine;
      -webkit-text-fill-color: $color-default-white;
    }
  }

  input {
    padding: 18px 74px;
    color: $color-default-white;
    text-align: center;

    .invalid-form & {
      outline-color: $color-geraldine;
      outline-width: 2px;
    }

    &::placeholder {
      font-size: 12px;
      line-height: 14px;
      text-transform: uppercase;
      color: fade-out($color: $color-gainsboro, $amount: 0.5);
    }
  }

  textarea {
    border-width: 17px 2px 10px;
    border-color: $color-transparent;
    border-style: solid;
  }

  &__label {
    position: absolute;
    z-index: 1; // для поднятия над outline у input
    top: 18px;
    left: 20px;
    color: currentColor;
    transition: top $trans-default, font-size $trans-default;
  }

  &__text {
    position: absolute;
    right: 20px;
    top: 30%;
    pointer-events: none;
  }

  &.is-invalid {
    color: red;

    input,
    textarea {
      @include focus {
        color: $color-default-black;
      }
    }
  }
}

.custom-textarea {
  --textarea-label-margin-top: 50px;

  @include vp-767 {
    --textarea-label-margin-top: 25px;
  }

  &.is-value,
  &:focus-within {
    .custom-textarea__label {
      top: calc(var(--textarea-label-margin-top) * -1);
    }
  }

  &__label {
    margin-top: var(--textarea-label-margin-top);
    transition: top $trans-400, font-size $trans-400;
  }

  textarea {
    min-height: calc(var(--textarea-label-margin-top) + 45px);
  }

  &[disabled] {
    label,
    input,
    textarea {
      pointer-events: none;
      user-select: none;
      opacity: 0.5;
    }
  }
}
