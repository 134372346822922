.custom-select {
  position: relative;
  width: 267px;
  min-width: 140px;

  @media screen and (max-width: 767px) {
    padding: 4px 0 14px;
    width: 100%;
  }

  @include vp-767 {
    padding: 0;
  }

  &__label {
    position: absolute;
    top: 50%;
    left: 10px;

    transform: translateY(-50%);
    transition: transform 0.3s ease, opacity  0.3s ease;
    pointer-events: none;

    @media screen and (max-width: 767px) {
      transform: translateY(calc(-50% - 5px));
    }
  }

  &__placeholder-desktop {
    pointer-events: none;

    @include vp-767 {
      display: none;
    }
  }

  &__placeholder-mobile {
    display: none;
    pointer-events: none;
    padding-top: 10px;
    margin-right: 14px;
    text-align: center;

    @include vp-767 {
      display: block;
    }

    @media (max-width: 500px) {
      text-align: left;
      padding-left: 15px;
    }
  }

  &__placeholder {
    position: absolute;
    width: 100%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    transition: transform 0.3s ease, opacity 0.3s ease;
    pointer-events: none;

    color: $color-default-white;
    font-weight: 700;
    font-size: 12px;
    line-height: 15px;
    text-transform: uppercase;
    text-align: center;

    @media screen and (max-width: 767px) {
      transform: translate(-50%, calc(-50% - 5px));
      font-size: 10px;
      line-height: 13px;
    }
  }

  select {
    position: absolute;
    width: 1px;
    height: 1px;
    margin: -1px;
    padding: 0;
    overflow: hidden;

    white-space: nowrap;
    border: 0;

    clip: rect(0 0 0 0);
    clip-path: inset(100%);
  }

  &__button {
    display: flex;
    align-items: center;
    width: 100%;
    height: 48px;
    padding: 5px 10px;
    background-color: $color-mulled-wine;
    border: none;
    outline: none;
    cursor: pointer;
    color: $color-default-white;
    transition: background-color 0.3s ease-out, color 0.3s ease-out;

    @media screen and (max-width: 767px) {
      height: 44px;
    }

    // .is-valid & {
    //   background-color: $color-geraldine;
    // }

    .is-open & {
      background-color: $color-sea-green;
    }

    @include hover-focus {
      background-color: $color-mulled-wine-dark;
      color: fade-out($color: $color-nepal, $amount: 0.5)
    }

    &:focus {
      outline: 2px solid $color-mulled-wine;
      outline-offset: 2px;
    }

    &:disabled {
      pointer-events: none;
      opacity: 0.3;
    }
  }

  &__list {
    position: absolute;
    left: 2px;
    bottom: 12px;
    z-index: 1;
    visibility: hidden;

    width: 99%;
    max-height: 170px;
    margin: 0;
    padding: 0;
    overflow-y: auto;

    scrollbar-width: thin;
    scrollbar-color: $color-dodger-blue transparent;

    &::-webkit-scrollbar-track {
      background: transparent;
    }

    &::-webkit-scrollbar {
      width: 8px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: $color-dodger-blue;
      // border-radius: 20px;
      // border: 3px solid  rgba(255, 255, 255, 0.99);
    }

    list-style: none;
    background-color: #ffffff;
    box-shadow: 0 5px 5px 0 rgba(221, 221, 221, 1);
    box-shadow: 0 0 0 2px rgb(255, 255, 255), 0.3em 0.3em 1em rgba(0, 0, 0, 0.3);
    transform: translateY(99%);
    opacity: 0;
    transition: opacity 0.6s ease-out, transform 0.3s ease-out;
  }

  &__item {
    padding: 13px 15px;
    outline: none;
    cursor: pointer;
    transition: background-color 0.3s ease;
    border-bottom: 1px solid $color-line;
    color: $color-mulled-wine;

    &--mod {
      font-weight: 700;
      font-size: 12px;
      line-height: 15px;
      text-transform: uppercase;

      @media screen and (max-width: 767px) {
        font-size: 10px;
        line-height: 13px;
      }
    }


    &[aria-selected="true"] {
      background-color: rgba(0, 0, 0, 0.15);

      @media (hover: hover) {
        &:hover:not(.focus-visible) {
          &:focus {
            outline: 2px solid $color-geraldine;
            outline-offset: 2px;
          }
        }
      }

      &.focus-visible:focus {
        background-color: $color-nepal;
      }
    }

    @media (hover: hover) {
      &:hover:not(.focus-visible) {
        background-color: $color-nepal;
      }
    }

    &.focus-visible:focus {
      background-color: rgba(0, 0, 0, 0.4);
    }
  }

  &__error {
    position: absolute;
    bottom: -15px;
    left: 0;

    color: #ff1553;

    opacity: 0;
    transition: opacity 0.1s ease;
  }

  &__text {
    font-weight: 700;
    font-size: 12px;
    line-height: 15px;
    text-transform: uppercase;
    flex-shrink: 0;
    width: 100%;
    overflow: hidden;
    pointer-events: none;
    text-align: center;
    white-space: nowrap;
    text-overflow: ellipsis;
    opacity: 0;
    transition: opacity 0.3s ease 0.1s;

    br {
      display: none;
    }

    @media screen and (max-width: 767px) {
      font-size: 10px;
      line-height: 13px;
    }
  }

  &__icon {
    position: relative;
    right: 10px;
    flex-shrink: 0;
    width: 5px;
    height: 10px;
    margin-left: auto;
    background-color: transparent;
    transform: rotate(-45deg);
    transition: transform 0.3s ease;
    // clip-path: polygon(100% 0, 50% 100%, 0 0);
    border: 6px solid $color-dodger-blue;
    border-bottom-color: transparent;
    border-right-color: transparent;

    // @media screen and (max-width: 767px) {
    //   border: 4px solid $color-dodger-blue;
    //   width: 4px;
    //   height: 8px;
    // }

    .is-valid & {
      border-color: $color-default-white;
      border-bottom-color: transparent;
      border-right-color: transparent;
      // background-color: $color-default-white;
    }
  }

  &.is-disabled {
    pointer-events: none;

    .custom-select__list {
      display: none;
    }
  }

  &.open-up {
    .custom-select__list {
      bottom: auto;
      top: 0;
      transform: translateY(calc(-100% + 5px));
    }
  }

  &.is-open {
    .custom-select__list {
      visibility: visible;
      opacity: 1;
      transform: translateY(108%);
      transition: opacity 0.2s cubic-bezier(.17,.67,.19,.95) 0.1s, transform 0.3s ease-out;
    }

    .custom-select__icon {
      transform: rotate(-135deg);
    }

    .custom-select__label {
      opacity: 0;
    }
  }

  &.not-empty {
    .custom-select__text {
      opacity: 1;

      @media (max-width: 500px) {
        text-align: left;
        padding-left: 5px;
      }
    }

    .custom-select__label {
      opacity: 0;
    }

    .custom-select__placeholder {
      opacity: 0;
    }
  }

  &.is-invalid {
    .custom-select__error {
      opacity: 1;
    }

    .custom-select__button {
      border: 2px solid #ff1553;

      &:focus,
      &:hover {
        border: 2px solid rgba(#ff1553, 0.5);
      }
    }
  }

  &--placeholder {
    .custom-select__label {
      transform: translateY(calc(-50% - 40px));
      opacity: 1;
    }

    &.is-open .custom-select__label {
      opacity: 1;
    }

    &.not-empty .custom-select__label {
      opacity: 1;
    }
  }

  &.slide-up.not-empty,
  &.slide-up.is-open {
    .custom-select__label {
      transform: translateY(calc(-50% - 40px));
      opacity: 1;
    }

    .custom-select__text {
      opacity: 1;
    }
  }

  .input-message {
    position: absolute;
    right: 0;
    bottom: -5px;

    &--invalid {
      color: #ff0000;
    }

    &--valid {
      color: #15ab07;
    }
  }
}
