.table {
  border-collapse: collapse;
  width: 100%;
  padding: 0;
  margin-top: 10px;

  tbody {
    width: 100%;
  }

  button {
    padding: 0;
    background-color: transparent;
    border-radius: 5px;
    transition: outline 0.1s cubic-bezier(0.165, 0.84, 0.44, 1);
    outline-offset: -1px;

    @include hover-focus {
      background-color: transparent;
      border-top-right-radius: 5px;
    }

    @include hover {
      outline: 2px solid $color-mulled-wine;
    }

    @include vp-1023 {
      margin: 0 auto;
    }
  }

  & tr {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid $color-line;

    &:first-child {
      position: relative;
      border-top: 1px solid $color-line;

      @include vp-767 {
        display: none;
      }

      &::after {
        z-index: -1;
        position: absolute;
        left: 0;
        top: 5%;
        content: "";
        width: 100%;
        height: 90%;
        background-color: $color-line;
        opacity: 0.5;
      }
    }

    &:nth-child(2) {
      @include vp-767 {
        border-top: 1px solid $color-line;
        margin-top: -1px;
      }
    }
  }

  & svg {
    display: inline-block;
    vertical-align: top;

    @include vp-1023 {
      width: 35px;
      height: 35px;
    }

    @include vp-767 {
      width: 19px;
      height: 19px;
      align-self: flex-start;
    }
  }

  & td, th {
    position: relative;
    display: flex;
    align-items: center;
    padding: 15px;
    width: 20%;
    flex-grow: 1;

    @include vp-767 {
      padding: 8px;
      width: calc(100% / 5);
    }
  }

  & th {
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;
    text-transform: uppercase;
    color: $color-mulled-wine;
    white-space: nowrap;

    @include vp-1023 {
      font-size: 10px;
      line-height: 12px;
    }
  }

  & td:nth-child(2) {
    @include vp-767 {
      display: none;

      @media print {
        display: block;
      }
    }
  }

  & td:last-child, td:nth-last-child(2) {
    @media print {
      display: none;
    }

    text-align: center;
    width: 10%;

    @include vp-767 {
      width: 5%;

      button {
        margin: 0 auto;
      }
    }
  }

  & td:last-child {
    button {
      border-radius: 20px;

      @include hover-focus {
        border-top-right-radius: 20px;
      }

      @include hover {
        outline-color: $color-geraldine;
      }
    }
  }

  & td:not(:last-child) {
    position: relative;

    &::after {
      position: absolute;
      right: 0;
      top: 5%;
      content: "";
      width: 1px;
      height: 90%;
      background-color: $color-line;
    }
  }

  & td:first-child {
    &:first-child {
      font-weight: 700;
    }
  }
}
