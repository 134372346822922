.modal-new {

  &--del {
    .modal__content {
      border: 5px solid fade-out($color: $color-geraldine, $amount: 0.2);
    }
  }

  &__wrapper-top {
    width: 100%;
    display: flex;
    justify-content: center;
    background-color: $color-mulled-wine;
    color: $color-default-white;
    box-shadow: 0px 4px 10px fade-out($color: $color-mulled-wine-dark, $amount: 0.5);

    font-weight: 700;
    font-size: 14px;
    line-height: 18px;
    text-align: center;
    text-transform: uppercase;
    padding-right: 20%;
    padding-left: 20%;

    @include vp-767 {
      font-size: 12px;
      line-height: 16px;
    }
  }

  &__sign-svg {
    position: absolute;
    top: calc(50% - 180px);
    left: calc(50% - 28px);
    transform: scale(0);
    opacity: 0;
    transition: transform 0.1s ease-out 0.3s, opacity 0.9s ease-out;

    .is-active & {
      opacity: 1;
      transform: scale(1);
    }
  }

  &__wrapper-bottom {
    position: relative;
    padding: 35px 100px 50px;

    @include vp-767 {
      padding: 30px 40px 40px;
    }

    @media screen and (max-width: 500px) {
      padding: 30px 10px 50px;
    }
  }

  &__text-box {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 20px;
  }

  &__text-first,
  &__text-second {
    width: 100%;
    margin: 0;
    text-align: center;
    text-transform: uppercase;
    color: $color-mulled-wine;
  }

  &__text-first {
    font-weight: 700;
    font-size: 16px;
    line-height: 22px;
  }

  &__text-second {
    font-size: 12px;
    line-height: 17px;
  }

  &__button-wrapper {
    width: 100%;
    display: flex;
    justify-content: center;
    column-gap: 10px;

    & button {
      padding-left: 0;
      padding-right: 0;
      width: 140px;
    }
  }

  &__input {
    margin-bottom: 20px;

    input[type="text"] {
      padding: 17px 74px;
      width: 100%;
      outline-color: $color-mulled-wine;
      background-color: $color-mulled-wine;
      cursor: pointer;

      &:hover {
        outline: 2px solid $color-dodger-blue;
        outline-offset: 2px;
      }

      &:focus {
        outline: 2px solid $color-geraldine;
        outline-offset: 2px;
      }
    }
  }

  & .custom-select {
    width: 100%;
    margin-bottom: 20px;

    @include vp-767 {
      font-size: 12px;
      line-height: 16px;
    }
  }

  & .custom-select__button {
    height: 55px;

    @include vp-767 {
      height: 48px;
    }
  }

  & .custom-select__text,
  & .custom-select__placeholder,
  & .custom-select__list {
    font-weight: 400;
    letter-spacing: 0.01em;
    opacity: 0.5;

    @include vp-767 {
      font-size: 12px;
      line-height: 16px;
    }
  }

  & .custom-select__placeholder-mobile {
    @media (max-width: 500px) {
      text-align: center;
    }
  }

  & .custom-select.not-empty {
    .custom-select__text {
      @media (max-width: 500px) {
        text-align: center;
      }
    }
  }
}
