.footer {
  position: relative;
  background-color: $color-mulled-wine;
  color: $color-default-white;
  flex-shrink: 0;
  margin-top: auto;
  border-top: 14px solid $color-nepal;

  @include vp-767 {
    border-top-width: 5px;
  }

  @media print {
    display: none;
  }

  &__wrapper {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    padding: 30px 0;
  }

  &__info {
    & p {
      margin: 0;
      text-align: right;
    }
  }

  &__devepolicy {
    margin: 0;
    padding: 12px 10px;
    text-align: center;
    font-size: 14px;
    line-height: 24px;
    background-color: $color-mulled-wine-dark;
    opacity: 0.5;

    & span {
      color: $color-nepal;
    }

    @include vp-767 {
      font-size: 10px;
      line-height: 15px;
    }
  }
}
