.header {
  position: relative;
  background-color: $color-dodger-blue;
  color: $color-default-white;
  padding: 0;
  --header-min-height: 155px;

  @media print {
    display: none;
  }

  &__wrapper {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    min-height: var(--header-min-height);
  }

  &__top-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    padding-right: 6%;
    padding-top: 0.7%;
    display: flex;
    justify-content: flex-end;

    @media (max-width: 1300px) {
      padding-right: 2%;
    }

    @include vp-767 {

    }
  }

  &__box-users {
    max-width: 230px;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;

    & img {
      display: block;
      border-radius: 50%;
      overflow: hidden;
      border: 1px solid $color-dodger-blue;
      object-fit: cover;
    }

    @media (max-width: 1300px) {
      max-width: auto;
    }
  }

  &__box-users-text {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding-left: 10px;

    & p {
      margin: 0;
    }

    .header__users-status {
      font-weight: 700;
    }

    @media (max-width: 1300px) {
      display: none;
    }
  }

  &__bottom-wrapper {
    position: relative;
    display: grid;
    grid-template-columns: minmax(0, 100px) auto  minmax(0, 200px);
    align-items: center;
    justify-content: center;
    column-gap: 30px;
    padding: 0;
    min-height: var(--header-min-height);

    @media (max-width: 1300px) {
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      --header-min-height: 170px;
      column-gap: 0;
    }

    @include vp-1023 {
      margin-top: 20px;
    }

    @media (max-width: 500px) {
      --header-min-height: 110px;
    }
  }

  &__box-img {
    grid-column: 1 / 2;
    position: relative;
    border-radius: 50%;
    overflow: hidden;
    width: 6.5vw;
    height: 6.5vw;

    & img {
      position: absolute;
      top: 0;
      left: 0;
      width: 101%;
      height: 101%;
      object-fit: cover;
    }

    @media (min-width: 1300px) {
      max-width: 125px;
      max-height: 125px;
    }

    @media (max-width: 1300px) {
      display: none;
    }
  }

  &__box-text {
    grid-column: 2 / 3;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 15px;

    & p,  & h1 {
      margin: 0;
      text-align: center;
    }

    & h1 {
      font-size: 30px;
      line-height: 36px;
      font-weight: 700;
      text-transform: uppercase;
      margin-top: 5px;
      margin-bottom: 5px;

      @include vp-767 {
        font-size: 20px;
        line-height: 25px;
      }
    }

    & p:first-child {
      opacity: 0.5;
    }

    & p:first-child span,
    & p:last-child  {
      font-size: 18px;
      line-height: 22px;
      text-transform: uppercase;

      @include vp-767 {
        font-size: 10px;
        line-height: 13px;
      }
    }

    & p:first-child {
      @include vp-767 {
        position: absolute;
        left: -2%;
        top: -7%;

        @include vp-767 {
          left: 0;
        }
      }
    }
  }

  &__bottom-wrapper-button {
    grid-column: 3 / 4;
    margin-top: auto;
    margin-bottom: 5px;

    @media (max-width: 1300px) {
      position: absolute;
      right: 0;
      bottom: 5px;
      margin-top: 0;
      margin-bottom: 0;
      min-width: 200px;
    }

    @include vp-1023 {
      position: relative;
      margin-top: 20px;
    }

    @include vp-767 {
      padding: 14px 30px;
    }

    @media (max-width: 500px) {
      margin: 0;
      min-width: auto;
      bottom: 0;
      position: relative;
      padding: 14px 20px;
    }
  }
}
