.visually-hidden {
  position: absolute;

  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;

  white-space: nowrap;

  border: 0;

  clip: rect(0 0 0 0);
  clip-path: inset(100%);
}

.scroll-lock-ios {
  position: fixed;
  overflow: hidden;
}

.scroll-lock {
  overflow: hidden;
}

.no-scrollbar {
  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
}

.no-transition {
  /* stylelint-disable-next-line */
  transition: none !important;
}

.js-focus-visible :focus:not(.focus-visible) {
  outline: none;
}

.no-bm {
  margin-bottom: 0;
}

.active-holidays {
  &::before {
    position: absolute;
    bottom: 0;
    left: 0;
    content: "";
    width: 7px;
    height: 7px;
    background-color: $color-sea-green;
  }
}
