@mixin retina {
  @media (min-resolution: $retina-dpi), (min-resolution: $retina-dppx) {
    @content;
  }
}

@mixin hover {
  @media (hover: hover) and (pointer: fine) {
    &:hover:not(.focus-visible) {
      @content;
    }
  }
}

@mixin focus {
  &.focus-visible:focus {
    @content;
  }
}

@mixin active {
  &.focus-visible:active {
    @content;
  }
}

@mixin hover-focus {
  @media (hover: hover) and (pointer: fine) {
    &:hover:not(.focus-visible) {
      @content;
    }
  }

  &.focus-visible:focus {
    @content;
  }
}

@mixin interactive-style {
  @media (hover: hover) and (pointer: fine) {
    &:hover:not(.focus-visible) {
      @content;
    }
  }

  &.focus-visible:focus {
    @content;
  }

  &:active {
    @content;
  }
}

@mixin animation($name, $duration, $tf, $fm: both) {
  animation-name: $name;
  animation-duration: $duration;
  animation-timing-function: $tf;

  animation-fill-mode: $fm;
}

// Desktop first

@mixin vp-1919 {
  @media (max-width: ($vp-1920 - 1px)) {
    @content;
  }
}

@mixin vp-1023 {
  @media (max-width: ($vp-1024 - 1px)) {
    @content;
  }
}

@mixin vp-767 {
  @media (max-width: ($vp-768 - 1px)) {
    @content;
  }
}

@mixin vp-500 {
  @media (max-width: ($vp-500)) {
    @content;
  }
}

@mixin vp-375 {
  @media (max-width: ($vp-375)) {
    @content;
  }
}

@mixin vp-320 {
  @media (max-width: $vp-320) {
    @content;
  }
}

@mixin vp-desktop-only {
  @media (min-width: $vp-1024 - 1px) {
    @content;
  }
}
