.box-current-date {
  width: 100%;
  padding-left: 40px;

  @include vp-1023 {
    padding-left: 10px;
    padding-right: 10px;
  }

  & p, span {
    margin: 0;
    font-weight: 700;
    font-size: 12px;
    line-height: 15px;
    text-transform: uppercase;
    color: $color-mulled-wine;

    @include vp-1023 {
      font-size: 10px;
      line-height: 13px;
    }
  }

  & span:first-child {
    @include vp-1023 {
      display: none;
    }
  }

  & p {
    position: relative;
    padding-left: 16px;
    top: 50%;
    transform: translateY(-50%);


    @include vp-1023 {
      padding-left: 12px;
    }

    &::before {
      position: absolute;
      left: 0;
      top: calc(50% - 5px);
      content: "";
      width: 10px;
      height: 10px;
      border-radius: 50%;
      background-color: $color-sea-green;

      @include vp-1023 {
        width: 8px;
        height: 8px;
      }
    }
  }
}
