.entrance {
  position: relative;
  min-height: 100vh;
  background-color: $color-mulled-wine;

  &__wrapper {
    position: absolute;
    max-width: 500px;
    width: 90%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 50px;
    text-align: center;

    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }

  &__title {
    font-size: 18px;
    line-height: 24px;
    text-transform: uppercase;
    color: fade-out($color: $color-default-white, $amount: 0.5);
    margin-bottom: 30px;

    @include vp-767 {
      font-size: 14px;
      line-height: 19px;
    }
  }

  &__button {
    padding: 20px 78px;

    @include hover {
      background-color: $color-sea-green;
    }
  }

  form {
    position: relative;
  }

  &__invalid-text {
    position: absolute;
    left: 15%;
    top: -20%;
    color: $color-geraldine;
    font-size: 11px;
    text-transform: uppercase;
    opacity: 0;
    transition: opacity 0.6s ease-out;

    .invalid-form & {
      opacity: 1;
    }
  }

  &__input {
    & input {
      max-width: 200px;
      border-radius: 40px;
      margin-bottom: 14px;
    }
  }
}
